<template>
  <div class="table_pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      background
      :total="total"
      :page-sizes="pageSizes"
    >
    </el-pagination>
    <div class="jumpr">
      <span>跳至</span><el-input v-model="jumprSize" /><span>页</span
      ><el-button size="mini" style="margin-left: 10px" type="primary" @click="handleCurrentChange(jumprSize)">确定</el-button>
    </div>
  </div>
</template>

<script>
import { TABLE } from '/app.config.js';
export default {
  props: {
    total: {
      type: Number,
      required: true,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data() {
    return {
      pageSizes: TABLE.page_sizes,
      jumprSize: this.currentPage
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('fetch', { currentPageSize: val });
    },
    handleCurrentChange(val) {
      let page = Number(val);
      this.$emit('fetch', { currentPage: page });
    }
  }
};
</script>

<style lang="scss">
.table_pagination {
  display: flex;
  .jumpr {
    .el-input {
      width: 52px;
      margin: 0 10px;
      input {
        height: 28px;
        padding: 0;
        text-align: center;
        line-height: 28px;
      }
    }
  }
}
</style>
