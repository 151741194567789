<template>
  <div class="list-container">
    <div class="slot">
      <slot name="other"></slot>
    </div>
    <div class="header">
      <div class="addOrdel">
        <el-button class="iconfont icon-xinzeng" @click="$emit('add')" v-if="isAdd">{{ addText }}</el-button>
        <el-button class="iconfont icon-piliangshanchu" type="danger" plain @click="$emit('multipleDel')" v-if="multipleDelCheck">批量删除</el-button>
      </div>
      <div class="file" v-if="uploadFile.length">
        <el-button class="iconfont" @click="$router.go(-1)" v-if="uploadFile.includes('quit')">返回</el-button>
        <el-upload
          class="upload-demo"
          :action="VUE_APP_API_BASE_URL + 'fe/fe_user_orders/importUsers'"
          :on-progress="handleProgress"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :limit="1"
          :show-file-list="false"
          :headers="token"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <!-- <el-button size="small" type="primary">导入</el-button> -->
          <el-button class="iconfont icon-daoru" v-if="uploadFile.includes('import')">导入</el-button>
        </el-upload>

        <download-excel :data="data" :fields="fields" :name="excelName" v-if="uploadFile.includes('export')">
          <el-button class="iconfont icon-daochu" @click="$emit('exportFile')">导出</el-button></download-excel
        >
        <el-button class="iconfont icon-dcxiazai1" @click="$emit('downTemplate')" v-if="uploadFile.includes('download')">下载导入模板</el-button>
      </div>
      <div class="slot">
        <slot name=""></slot>
      </div>
      <div class="right">
        <div class="search" v-if="search">
          <div class="input" v-if="searchInput">
            <searchInput :placeholder="placeholder" @change="onSearch" ref="searchInputDom" />
          </div>
          <div class="slot"><slot name="filter"></slot></div>
          <div class="btn"></div>
        </div>
        <div class="switch" v-if="grid">
          <div class="btn-grid" v-if="isTable">
            <el-button type="text" @click="switchType('grid')"><img src="./img/grid.png" alt="" /></el-button>
          </div>
          <div class="btn-table" v-else>
            <el-button type="text" @click="switchType('table')"><img src="./img/table.png" alt="" /></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main" :class="{ gridScrollY }">
      <div class="grid-wrap" v-if="isGrid">
        <slot name="grid"></slot>
      </div>
      <div class="table-wrap" v-else>
        <slot name="table"></slot>
      </div>
    </div>
    <div class="footer clearfix" v-if="pagination">
      <pagination class="pagination fr" v-bind="pagination" @fetch="fetch" />
    </div>
  </div>
</template>

<script>
console.log(process.env.VUE_APP_API_BASE_URL);
import pagination from '@/components/pagination/pagination.vue';
import searchInput from '@/components/input/search-input';
import JsonExcel from 'vue-json-excel';
export default {
  props: {
    isAdd: {
      type: Boolean,
      default: true
    },
    addText: {
      type: String,
      default: '新增'
    },
    placeholder: String,
    excelName: String,
    grid: {
      type: Boolean,
      default: true
    },
    searchInput: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    uploadFile: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    customReferenceCountKey: String,
    multipleSelection: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  data() {
    return {
      VUE_APP_API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
      token: {},
      fileList: [],
      gridScrollY: false,
      type: this.grid ? 'grid' : 'table'
    };
  },
  mounted() {
    let token = localStorage.getItem('token') || '';
    this.token = {
      accessToken: token,
      requestType: '2'
    };
  },
  methods: {
    handleProgress() {
      this.loading = this.$loading({
        lock: true,
        text: '正在上传，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleSuccess(file) {
      this.loading.close();
      if (file.resp_code === 1) {
        this.$message.error(file.resp_msg);
        this.fileList = [];
      } else {
        this.$message.success('导入成功！');
        this.$emit('fetch');
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    importFile() {
      // const file = null;
      // this.$emit('uploadDone', file);
    },
    switchType(type) {
      this.type = type;
    },
    onSearch(searchText) {
      // this.$emit('update:searchText', searchText);
      this.$emit('titleSearch', searchText);
    },
    fetch(params) {
      this.$emit('fetch', params);
    },
    clear() {
      this.$refs.searchInputDom.clear();
    }
  },
  computed: {
    isGrid() {
      return this.type === 'grid';
    },
    isTable() {
      return this.type === 'table';
    },
    multipleDelCheck() {
      return this.multipleSelection.length && !this.multipleSelection.some(item => item[this.customReferenceCountKey] > 0);
    }
  },
  components: {
    pagination,
    searchInput,
    downloadExcel: JsonExcel
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .file {
      margin-left: 10px;
    }
    .slot {
      flex: 1;
      font-size: initial;
    }
    .right {
      display: flex;
      align-items: center;
      .search {
        display: flex;
        .input {
          margin-right: 17px;
          /deep/ .el-input {
            width: 260px;
          }
        }
      }
      .switch {
        display: flex;
        align-items: center;
        .btn-grid,
        .btn-table {
          margin-right: 5px;
        }
      }
    }
  }
  .main {
    flex: 1;
    overflow-y: hidden;
    .grid-wrap,
    .table-wrap {
      height: 100%;
    }
  }
  .gridScrollY {
    overflow-y: auto;
  }
  .footer {
    margin-top: 31px;
  }
}
.main {
  padding: 0 !important;
}
</style>
